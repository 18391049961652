.side-img{
    overflow: hidden !important;
    background-image: url("./images/images.png") !important;
    height: 100vh !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.cover{
    background-color: rgba(4, 84, 152, 0.7) !important;/* Black w/opacity/see-through */
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh !important;
    background-size: cover !important;
}