textarea{
  height: 250px !important;

}
.banner {
  background-image: url("./images/banner.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.banner3{
  background-image: url("./images/banner3.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.banner4{
  background-image: url("./images/banner4.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.banner-5{
  background-image: url("./images/booth.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.navigation{
  overflow-x: scroll;
}
.banner2 {
  overflow: hidden !important;
  background-image: url("./images/banner2.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.rounded-40 {
  border-radius: 40px !important;
}
.rounded-30{
  border-radius: 30px !important;
}

.main-session {
  height: 65vh;
  overflow-y: scroll;
}

.main-session::-webkit-scrollbar {
  display: none;
}
.navigation::-webkit-scrollbar{
  display: none;
}
