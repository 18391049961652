@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --custom-blue: #045498;
  --custom-grey: #EEEEEE;
}
.d-flex .active{
  background-color: var(--custom-blue);
  color: #fff;
  border-radius: 30px;
}
.open-sans {
  font-family: "Open Sans", sans-serif;
}
.bg-custom-grey{
  background-color:var(--custom-grey);
}
.bg-custom-blue {
  background-color: var(--custom-blue);
}
.border-blue{
  border-color: var(--custom-blue) !important;
}

.btn-blue {
  background-color: var(--custom-blue);
  color: white;
}

.fw-100{
  width: 100% !important;
}

.btn-outline-blue {
  background-color: transparent;
  color: var(--custom-blue);
  border-color: var(--custom-blue) !important;
}

.option{
  background-color: var(--custom-blue);
  color: white;
  border: none;
  border-radius: 30px !important;
}
.btn-outline-white{
  background-color: transparent;
  color: #fff;
  border-color: #fff 
}
.btn-outline-blue:hover {
  background-color: var(--custom-blue);
  color: #fff !important;
}
.vh-65{
    height: 65vh !important;
}
.vh-55{
  height: 55vh !important;
}
.sponsor{
  height: 100px;
  width: 100px;
}
.text-blue {
  color: var(--custom-blue);
}
input[type="file"] {
  display: none;
}
.icon {
  height: 2em;
  width: 2em;
}

.icon-sm{
  height: 1em;
  width: 1em;
}
.icon-lg{
  height: 3em;
  width: 3em;
}
.icon-md{
  height: 2em;
  width: 2em;
}
.mx-w-30{
  max-width: 8em !important;
}

.z-5 {
  z-index: 5;
}

.line {
  z-index: o;
  text-align: center !important;
  border-bottom: 2px solid #747474 !important;
  line-height: 0.1em !important;
}

.top-35 {
  top: 10% !important;
}
.loading{
  width: 5em;
  height: 5em;
}
.icon:hover {
  cursor: pointer;
}
.no-style{
  text-decoration: none !important;
}
.cursor-pointer{
  cursor: pointer;
}
footer{
  background-image: url("./images/bg_footer.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.h-10{
  height: 10%;
}
.w-20{
  width: 20%;
}
.h-30{
  height: 30%;
}
.w-30{
  width: 30%;
}
.h-35{
  height: 35%;
}
.w-35{
  width: 35%;
}
.w-55{
  width: 55% !important;
}
.w-65{
  width: 65%;
}
.z-6{
  z-index: 6;
}
.w-60{
  width: 60%;
}
.h-60{
  height: 65%;
}
.w-80{
  width: 80%;
}
.w-85{
  width: 85%;
}
.h-95{
  height: 95% !important;
}
.h-90{
  height: 90% !important;
}
.vh-90{
  height: 90vh !important;
}
.h-40{
  height: 40% !important;
}
.w-40{
  width: 40% !important;
}
.h-45{
  height: 45%;
}
.text-w-800{
  font-weight: 800;
}
.border-white{
  border-color: white !important;
}
.border-radius-20{
  border-radius: 20px;
}
.border-radius-left{
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}
.border-radius-right{
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}
.text-w-3{
  font-weight: 300 !important;
}
.text-w-8{
  font-weight: 800 !important;
  font-size: large;
}
.message-right{
  height: auto;
  max-width: 30%;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}
/* Message Left */
.message-left{
  height: auto;
  max-width: 30%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.overflow-scroll::-webkit-scrollbar{
  display: none;
}
